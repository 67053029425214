import Player from '@vimeo/player';
import 'slick-carousel';
import lozad from 'lozad';

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

//===============================
//======== Popup Contact Form =========
//===============================

$('#contact-us-popup form').on('submit', function (e) {
	e.preventDefault();

	// let form_data = new FormData($(this)[0]);

	let form_data = {
		cf_email: $('input[name="popup-email"]').val(),
		cf_name: $('input[name="popup-fname"]').val(),
		cf_number: $('input[name="popup-number"]').val(),
		cf_callmeback: $('input[name="popup-callmeback"]').val(),
		// cf_company: $('input[name="cf_company"]').val(),
		// cf_department: $('select[name="cf_department"]').val(),
		// cf_where: $('select[name="popup-where"]').val(),
		cf_message: $('textarea[name="popup-message"]').val(),
		cf_referringpage: $('textarea[name="popup-referringpage"]').val(),
		// cf_accept: $('input[name="cf_accept"]').val(),
	};

	$.ajax({
		type: 'POST',
		dataType: 'json',
		url: 'https://portal.storminternet.co.uk/feedback',
		data: form_data,

		// what happens on success
		success: function (result) {
			let data = result;

			if (data.fistank_response == 'success') {
				window.location = '/thank-you';
			} else if (data.fistank_response == 'repeat-submission') {
				$('.repeat-message').addClass('show');
			} else if (data.fistank_response == 'error') {
				$('.failed-message').addClass('show');
			}
		},
		error: function (XHR, status, error) {
			console.log(status + ': ' + error);
		},
	});
});

$('#contact-us-popup-trigger').on('click', function () {
	$('body').addClass('no-scroll');
	$('#contact-us-popup').addClass('active');
});

$('#contact-us-popup .contact-us-popup__inner > button').on('click', function () {
	$('body').removeClass('no-scroll');
	$('#contact-us-popup').removeClass('active');
});

// POPUP GRID

$('.popup-grid-block .popup-grid-list__item').on('click', function () {
	let popup_content = $(this).find('.popup-content').html();
	let popup = $('#popup-grid-overlay');
	let popup_inner = popup.find('.popup-grid-overlay__inner');
	popup_inner.html(popup_content);
	popup.addClass('active');

	popup_inner.on('click', '.close', function () {
		popup.removeClass('active');
	});
});

window.onload = function () {
	let modal = $('.get-started');
	let btn = modal.find('.wpcf7-submit');

	btn.attr('value', 'Book a call');

	if ($('.page-header').hasClass('dark-blue') || $('.page-header').hasClass('grey')) {
		let nav = $('#nav-bar');
		let logo = $('#nav-bar .brand');
		let search = $('#nav-bar .search-wrap');

		$(nav).css('color', '#fff');
		$(nav).find('.top-level').css('color', '#fff');

		$(nav)
			.find('.top-level')
			.on({
				mouseenter: function () {
					$(this).css('color', '#ffd425');
				},
				mouseleave: function () {
					$(this).css('color', '#fff');
				},
			});

		$(logo).children('svg').children('path').css('fill', '#fff');
		$(search).find('svg').children('path').css('fill', '#fff');
	}
};

$('.tooltip').on({
	mouseenter: function () {
		let tooltipTxt = $(this).data('tooltip');
		let tooltipBox = $('#tooltip');

		$(tooltipBox).html('<p>' + tooltipTxt + '</p>');
		//$(tooltipBox).show(200);

		let locTop = $(this).offset().top;
		let locLeft = $(this).offset().left;

		let topCalc = locTop - $(tooltipBox).height();

		if ($(window).width() < 960) {
			$(tooltipBox).css({
				display: 'block',
				top: topCalc - 20,
				left: locLeft,
			});
		} else {
			$(tooltipBox).css({
				display: 'block',
				top: topCalc - 24,
				left: locLeft - 90,
			});
		}
	},
	mouseleave: function () {
		let tooltipBox = $('#tooltip');
		$(tooltipBox).css({
			display: 'none',
		});
	},
});

//========================
//====== Navigation ======
//========================

window.onscroll = function () {
	stickykNav();
};

function navChangeOn() {
	if ($('.page-header').hasClass('dark-blue') || $('.page-header').hasClass('grey')) {
		let nav = $('#nav-bar');
		let logo = $('#nav-bar .brand');
		let search = $('#nav-bar .search-wrap');

		$(nav).css('color', '#fff');
		$(nav).find('.top-level').css('color', '#fff');

		$(nav)
			.find('.top-level')
			.on({
				mouseenter: function () {
					$(this).css('color', '#ffd425');
				},
				mouseleave: function () {
					$(this).css('color', '#fff');
				},
			});

		$(logo).children('svg').children('path').css('fill', '#fff');
		$(search).find('svg').children('path').css('fill', '#fff');
	}
}

function navChangeOff() {
	if ($('.page-header').hasClass('dark-blue') || $('.page-header').hasClass('grey')) {
		let nav = $('#nav-bar');
		let logo = $('#nav-bar .brand');
		let search = $('#nav-bar .search-wrap');

		$(nav).css('color', '#000');
		$(nav).find('.top-level').css('color', '#000');

		$(nav)
			.find('.top-level')
			.on({
				mouseenter: function () {
					$(this).css('color', '#ffd425');
				},
				mouseleave: function () {
					$(this).css('color', '#000');
				},
			});

		$(logo).children('svg').children('path').css('fill', '#22458f');
		$(search).find('svg').children('path').css('fill', '#22458f');
	}
}

// Get the navbar
var navbar = document.getElementById('nav-bar');

// Get the offset position of the navbar
// var sticky = navbar.offsetTop;
var sticky = 40;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickykNav() {
	if (window.pageYOffset >= sticky) {
		navbar.classList.add('sticky');
		navChangeOff();
	} else {
		navbar.classList.remove('sticky');
		navChangeOn();
	}
}
navbar.classList.add('transition-ready');

$('.main-nav li.top-level').on({
	mouseenter: function (e) {
		e.preventDefault();
		$('.main-nav .sub-nav li').removeClass('active');
		$('.third-tier-nav').hide(200);
	},
	mouseleave: function (e) {
		e.preventDefault();
		$('.main-nav .sub-nav li').removeClass('active');
		$('.third-tier-nav').hide(200);
	},
});

$('.main-nav .sub-nav li a.has-subnav').on({
	mouseenter: function (e) {
		e.preventDefault();

		let tier = $(this).parent('.has-children').children().attr('href');

		if ($(this).parent().hasClass('has-children')) {
			$('.third-tier-nav').hide(200);

			$('.main-nav .sub-nav li').removeClass('active');
			$(this).parent().addClass('active');

			$(tier).show(200);
		}
	},
});

$('.main-nav .sub-nav li a.no-subnav').on({
	mouseenter: function (e) {
		e.preventDefault();

		$('.main-nav .sub-nav li').removeClass('active');
		$('.third-tier-nav').hide(200);
		$(this).parent().addClass('active');
	},
});

$('#mobile-nav-toggle').on('click', function () {
	$(this).toggleClass('active');
	$('#mobile-nav').toggleClass('active');
	$('body').toggleClass('no-scroll');
	$(navbar).toggleClass('side-menu');
});

$('.mobile-nav li.parent > a').on('click', function (e) {
	e.preventDefault();
	$(this).next('ul').toggle();
	$(this).toggleClass('active');
});

$('.mobile-nav li.kid-parent > a').on('click', function (e) {
	e.preventDefault();
	if ($(this).parent().hasClass('active')) {
		$(this).next('ul').slideUp(300);
		$(this).parent().removeClass('active');
	} else {
		$(this).parent().siblings().removeClass('active').find('ul').hide();
		$(this).parent().addClass('active');
		$(this).next('ul').slideDown(300);
	}
});

//================================
//======== Header - Video ========
//================================

let videoBtn = $('.video-container button');

videoBtn.on('click', function () {
	$(this).css('display', 'none');

	$(this).siblings('img').css('display', 'none');

	$(this).siblings('iframe').css('display', 'block');
});

//================================
//======== About - Video =========
//================================

let abtVid = '#about-intro .media-content';

$(abtVid + ' iframe').hover(
	//enter
	function () {
		$(abtVid + ' svg').css('transform', 'translate(-50%, -50%) scale(1)');
	},
	//leave
	function () {
		$(abtVid + ' svg').css('transform', 'translate(-50%, -50%) scale(0.8)');
	}
);

if ($('body').hasClass('page-id-71')) {
	let abtVim = new Player('about-video-vimeo');

	abtVim.on('play', function () {
		$(abtVid + ' svg').toggle();
	});

	abtVim.on('pause', function () {
		$(abtVid + ' svg').toggle();
	});
}

//========================
//======= Sliders ========
//========================

//Reviews Section Slider
const reviews_section_slider = $('#review-blocks__slick');

reviews_section_slider.slick({
	arrows: true,
	dots: true,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	centerMode: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: true,
				centerMode: false,
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 900,
			settings: {
				arrows: true,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '25px',
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});

//===============================
//=========== Sliders ===========
//===============================

// declare from page slider
const reviews_slider = $('#reviews-slider__slick');

//Force cloning of slides (Slick default wants > 5 or 6 slides) for infinite slider
if (reviews_slider.length <= 3) {
	// clone element
	$(reviews_slider).children().clone(true, true).appendTo(reviews_slider);
}

// init the slick slider
reviews_slider.slick({
	infinite: true,
	arrows: false,
	slidesToShow: 3,
	dots: false,
	centerMode: true,
	centerPadding: '50px',
	autoplay: true,
	autoplaySpeed: 2000,
	pauseOnHover: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 900,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 1,
			},
		},
	],
});

//=====================================
//======== Header- Breadcrumbs ========
//=====================================

// Breadcrumbs
$('#breadcrumb-anchor').on('click', function () {
	$(this).next()[0].classList.toggle('active');
});

$('.breadcrumb__dropdown svg').on('click', function () {
	$(this).parent()[0].classList.toggle('active');
});

$('.breadcrumb__dropdown .parents > li').hover(
	//enter
	function () {
		let hoverChild = $(this).children('.children');
		let childrenMenus = $('.breadcrumb__dropdown .children');

		for (let i = 0; i < childrenMenus.length; i++) {
			$(childrenMenus[i]).css('display', 'none');
		}

		if ($(this).hasClass('menu-item-object-service_cat')) {
			$('.breadcrumb__dropdown').css('height', '450px');
		} else {
			$('.breadcrumb__dropdown').css('height', 'auto');
		}

		hoverChild.css('display', 'block');
	},
	//leave
	function () {
		$('.current-menu-parent .children').css('display', 'block');

		if ('.breadcrumb__dropdown'.css('display') == 'block') {
			if ($(this).hasClass('menu-item-object-service_cat')) {
				$('.breadcrumb__dropdown').css('height', '450px');
			}
		} else {
			if ($(this).hasClass('menu-item-object-service_cat')) {
				$('.breadcrumb__dropdown').css('height', 'auto');
			}
		}
	}
);

//=========================
//===== FLEXI CONTENT =====
//=========================

// Tabs
$('.tabs__nav li').on('click', function (e) {
	e.preventDefault();

	//remove active class
	$('.tabs__nav li').each(function (index, item) {
		$(item).removeClass('active');
		$(item).removeClass('active-text');
	});
	// 	//add active class to clicked tab
	$(this).addClass('active');
	$(this).addClass('active-text');
	// 	$(this).prevAll().removeClass('active-text');
	// 	$(this).nextAll().removeClass('active-text');

	// 	//desktop only
	// 	if ($(window).width() > 1200) {
	// 		$(this).prevAll().addClass('active');
	// 	}

	// 	//hide all panels
	$('.tabs__item').css('display', 'none');
	// 	//get href of clicked tab for ID of panel
	let href = $(this).children().attr('href');
	// 	//display panel
	$(href).css('display', 'flex');
});

// Accordion
$('.accordion').on('click', function () {
	//get panel of clicked accordion item
	let panel = $(this).next();
	let panelWrapper = $(this).next().children();

	//toggle active class
	$(this)[0].classList.toggle('active');

	//get html of the cta
	let text = $(this)[0].innerHTML;
	let newTxt;

	//if the accordion is open
	if ($(this).hasClass('active')) {
		//true
		//change text to Close
		newTxt = text.replace('Read More', 'Close');
	} else {
		//false
		//change text to Read More
		newTxt = text.replace('Close', 'Read More');
	}

	//replace html
	$(this)[0].innerHTML = newTxt;

	//toggle active class to accordion panel
	panel[0].classList.toggle('active');

	if ($(panel[0]).hasClass('active')) {
		$(panel[0]).css({ maxHeight: panelWrapper.height() + 30 });
	} else {
		$(panel[0]).css({ maxHeight: 0 });
	}
});

//Slider
const flexi_slider = $('.flexi-content .slider__slick');

flexi_slider.slick({
	arrows: true,
	dots: true,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	centerMode: false,
});

//=========================
//========= Modal =========
//=========================

let modalBtn = $('.modal__btn');

modalBtn.on('click', function () {
	let modalId = this.dataset.modal;

	$('#' + modalId).css('display', 'block');

	disableScroll();
});

$('.close').on('click', function () {
	let modalId = $(this).parent().parent().parent().attr('id');

	$('#' + modalId).css('display', 'none');

	enableScroll();
});

//=========================
//====== FAQs Filter ======
//=========================

$('#filter-faqs').on('click', function (e) {
	e.preventDefault();

	let category = $('#service-filter').find(':selected').val();

	let service = $('#' + category)
		.find(':selected')
		.val();

	let questions = $('.accordion-data');

	let emptyCheck = 0;

	//reset
	for (let i = 0; i < questions.length; i++) {
		questions[i].classList.add('show');
		questions[i].classList.remove('hide');
		emptyCheck = 0;
		$('.no-results').css('display', 'none');
	}

	if (category) {
		for (let i = 0; i < questions.length; i++) {
			if (service) {
				if (questions[i].dataset.secondaryFilter != service) {
					questions[i].classList.add('hide');
					questions[i].classList.remove('show');
					emptyCheck++;
				}

				if (questions[i].dataset.serviceFilter != category) {
					questions[i].classList.add('hide');
					questions[i].classList.remove('show');
				}
			} else {
				if (questions[i].dataset.serviceFilter != category) {
					questions[i].classList.add('hide');
					questions[i].classList.remove('show');
					emptyCheck++;
				}
			}
		}
	}

	if (emptyCheck == questions.length) {
		$('.no-results').css('display', 'block');
	}
});

$('#service-filter').on('change', function () {
	let category = $('#service-filter').find(':selected').val();

	let filterFields = $('.secondary-filter');

	for (let i = 0; i < filterFields.length; i++) {
		let service = $(filterFields[i]).attr('id');
		filterFields[i].classList.remove('disable');
		filterFields[i].classList.add('hide');
		filterFields[i].classList.remove('show');

		if (category == service) {
			filterFields[i].classList.add('show');
			filterFields[i].classList.remove('hide');
		}

		if (category == 'empty') {
			filterFields[i].classList.add('hide');
			filterFields[0].classList.remove('hide');
			filterFields[0].classList.add('disable');
			filterFields[0].classList.add('show');
		}
	}
});

//===============================
//====== Utility Functions ======
//===============================

function disableScroll() {
	// Get the current page scroll position
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

	// if any scroll is attempted, set this to the previous value
	window.onscroll = function () {
		window.scrollTo(scrollLeft, scrollTop);
	};
}

function enableScroll() {
	window.onscroll = function () {};
}

$('#loadMoreResults').on('click', function () {
	let container = $('#load-more .load-more-item');

	let loaded = $('.shown-result').length || 9;
	let toLoad = 3;

	loaded = loaded + toLoad <= container.length ? loaded + toLoad : container.length;
	$('#load-more .load-more-item:lt(' + loaded + ')').addClass('shown-result');

	if (loaded == container.length) {
		$('#loadMoreResults').hide();
	}
});

//==============================
//========== Timeline ==========
//==============================

let timelineY = $('#journey .year');
//let timelineCont = timelineY.parent();

timelineY.on('click', function (e) {
	e.preventDefault();
	let timelineCont = $(this).parent();

	for (let i = 0; i < timelineY.length; i++) {
		$(timelineY[i]).parent().removeClass('active');
	}

	timelineCont.toggleClass('active');
});

//===============================
//========= AjaX Search =========
//===============================

$('#search-trigger').on('click', function () {
	$('#ajax-search-form').addClass('active');
	$(this).css('display', 'none');
});

$('#ajax-search-form svg').on('click', function () {
	$('#ajax-search-form').removeClass('active');
	$('#search-trigger').css('display', 'block');
});

function dosearch(t) {
	// do the ajax request for job search
	$.ajax({
		type: 'post',
		// eslint-disable-next-line no-undef, yarn build
		url: ajaxurl, // the localized name of your file
		data: {
			action: 'storm_ajax_search', // the wp_ajax_ hook name
			search: t,
		},

		// what happens on success
		success: function (result) {
			// if the ajax call returns no results
			if (result === 'error') {
				// set the html of the element with the class to no results
				$('.ajax-results').html('<a class="search-result-item no-results">No results found.</a>').fadeIn();

				// we have results to display
			} else {
				// populate the results markup in the element with the class of ajax-results
				$('.ajax-results').html(result).fadeIn();
				$('.load-spinner').css('display', 'none');
			}
		},
	});
}

var thread = null;

$('.ajax-search').keyup(function () {
	// clear our timeout variable - to start the timer again
	clearTimeout(thread);

	// set a variable to reference our current element ajax-search
	var $this = $(this);

	$('.load-spinner').css('display', 'block');

	// set a timeout to wait for a second before running the dosearch function
	thread = setTimeout(function () {
		if ($this.val() != '') {
			dosearch($this.val());
		} else {
			$('.ajax-results').fadeOut(200);
		}
	}, 1000);
});

//===============================
//======== Contact Form =========
//===============================

$('#contact-page-form').on('submit', function (e) {
	e.preventDefault();

	let form_data = {
		cf_email: $('input[name="cf_email"]').val(),
		cf_name: $('input[name="cf_name"]').val(),
		cf_number: $('input[name="cf_number"]').val(),
		cf_callmeback: $('input[name="cf_callmeback"]').val(),
		cf_company: $('input[name="cf_company"]').val(),
		cf_department: $('select[name="cf_department"]').val(),
		cf_where: $('select[name="cf_where"]').val(),
		cf_message: $('textarea[name="cf_message"]').val(),
		cf_referringpage: $('textarea[name="cf_referringpage"]').val(),
		// cf_accept: $('input[name="cf_accept"]').val(),
	};

	$.ajax({
		type: 'POST',
		dataType: 'json',
		url: 'https://portal.storminternet.co.uk/feedback',
		data: form_data,

		// what happens on success
		success: function (result) {
			let data = result;

			if (data.fistank_response == 'success') {
				window.location = '/thank-you';
			} else if (data.fistank_response == 'repeat-submission') {
				$('.repeat-message').addClass('show');
			} else if (data.fistank_response == 'error') {
				$('.failed-message').addClass('show');
			}
		},
		error: function (XHR, status, error) {
			console.log(status + ': ' + error);
		},
	});
});

$('.form-department').on('change', function (e) {
	var value = this.value;
	var tarForm = e.target.closest('form');

	$(tarForm).closest('.form-container').find('.account-message').removeClass('show');
	$(tarForm).closest('.form-container').find('.complaints-message').removeClass('show');
	$(tarForm).find('input').removeClass('disabled');
	$(tarForm).find('textarea').removeClass('disabled');
	$(tarForm).find('.form-where').removeClass('disabled');
	$(tarForm).find('.form-submit').attr('disabled', false);

	if (value == 'accounts' || value == 'support') {
		$(tarForm).closest('.form-container').find('.account-message').addClass('show');

		$(tarForm).find('input').addClass('disabled');
		$(tarForm).find('textarea').addClass('disabled');
		$(tarForm).find('.form-where').addClass('disabled');
		$(tarForm).find('.form-submit').attr('disabled', true);
	}

	if (value == 'complaints') {
		$(tarForm).closest('.form-container').find('.complaints-message').addClass('show');
	}
});

if ($('.flexi-content .feature-list-block').length > 0) {
	$('.feature-list-block__tab__inner').on('click', function () {
		let contentName = $(this).data('name');

		$('.feature-list-block__tabs__mobile button').css('visibility', 'hidden');

		//set active tab
		$('.feature-list-block__tab__inner').parent().removeClass('active');
		$(this).parent().addClass('active');

		//hide all tabbed content
		$('.feature-list-block__content').hide();
		$('#' + contentName).show();

		if ($(window).width() < 768) {
			let popupName = contentName.replace('tab', 'popup');

			console.log($('.feature-list-block__tabs__mobile button[data-popup="' + popupName + '"]'));

			$('.feature-list-block__tabs__mobile button[data-popup="' + popupName + '"]').css('visibility', 'visible');
		}
	});

	$('.feature-list-block__secondary-tabs a').on('click', function () {
		let contentName = $(this).attr('href');
		let parent = $(this).parent().parent().parent().parent();

		//hide columns
		$(parent).find('.feature-list-block__content__columns').removeClass('active');
		$(parent).find('.feature-list-block__content__columns').attr('style', 'display: none');
		$(contentName).addClass('active');
		$(contentName).attr('style', 'display: flex');
	});

	$('.popup-modal .close').on('click', function () {
		let modalID = $(this).parent().parent().attr('id');
		$('#' + modalID).hide();
		$('#' + modalID).removeClass('opened');
	});

	if ($('.feature-list-block').length > 0) {
		$(document).on('keydown', function (e) {
			if (e.key === 'Escape') {
				$('.popup-modal').hide();
				$('.popup-modal').removeClass('opened');
			}
		});
	}

	$('.popup-modal-link').on('click', function () {
		let modalID = $(this).data('popup');

		if ($('#' + modalID).hasClass('opened')) {
			$('#' + modalID).hide();
			$('#' + modalID).removeClass('opened');
		} else {
			$('#' + modalID).show();
			$('#' + modalID).addClass('opened');
		}

		$(document).on('mouseup', function (e) {
			var container = $('.popup-modal__inner');

			// if the target of the click isn't the container nor a descendant of the container
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				$('.popup-modal').hide();
				$('.popup-modal').removeClass('opened');
			}
		});
	});
}

if ($('.locations-block').length > 0) {
	$('select').on('change', function () {
		let selection = this.value;

		$('.location-item').removeClass('hidden');

		if (selection == 'type-all') {
			$('.location-item').removeClass('hidden');
		} else {
			let locations = $('.location-item');

			for (let i = 0; i < locations.length; i++) {
				const element = locations[i];

				if ($(element).data('type') != selection) {
					$(element).addClass('hidden');
				}
			}
		}
	});
}

//===============================
//========== Lightbox ===========
//===============================

$('.single-post img.lightbox').on('click', function () {
	let imgSrc = this.src;

	$('.lightbox-container').addClass('open');

	$('.lightbox-container img').attr('src', imgSrc);
});

$('.single-post .lightbox-container .close').on('click', function () {
	$('.lightbox-container').removeClass('open');
	$('.lightbox-container img').attr('src', '');
});

if ($('.flexi-content.table-block')) {
	if ($('.flexi-content.table-block .collaspable').hasClass('state-closed')) {
		let rows = $('.flexi-content.table-block .collaspable.state-closed');

		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			let rowID = $(row).attr('id');

			if (!$('#' + rowID).hasClass('wrapped')) {
				$(row)
					.nextUntil('tr.collaspable')
					.wrap('<div class="td_wrapper parent-' + rowID + '"></div>');
				$(row).addClass('wrapped');
			}

			$('#' + rowID)
				.siblings('.td_wrapper.parent-' + rowID + '')
				.each(function (index, element) {
					$(element)
						.find('td')
						.each(function (index, el) {
							$(this).css({
								'padding-top': '0px',
								'padding-bottom': '0px',
								'line-height': '0px',
								opacity: '0',
							});
							$(this).hide();
							$('#' + rowID).addClass('closed');
						});
				});
		}
	}

	$('.flexi-content.table-block .collaspable').on('click', function (event) {
		let row = $(event.target).parent();
		let rowID = $(row).attr('id');

		if (!$('#' + rowID).hasClass('wrapped')) {
			$(row)
				.nextUntil('tr.collaspable')
				.wrap('<div class="td_wrapper parent-' + rowID + '"></div>');
			$(row).addClass('wrapped');
		}

		$(this)
			.parent()
			.find('.td_wrapper.parent-' + rowID + '')
			.each(function (index, element) {
				//$(this).slideUp();

				if ($('#' + rowID).hasClass('closed')) {
					$(this)
						.find('td')
						.each(function (index, element) {
							$(this)
								.show()
								.animate(
									{
										'padding-top': '15px',
										'padding-bottom': '15px',
										'line-height': '15px',
										opacity: '1',
									},
									function () {
										$('#' + rowID).removeClass('closed');
									}
								);
						});
				} else {
					$(this)
						.find('td')
						.each(function (index, element) {
							$(this).animate(
								{
									'padding-top': '0px',
									'padding-bottom': '0px',
									'line-height': '0px',
									opacity: '0',
								},
								function () {
									$(this).hide();
									$('#' + rowID).addClass('closed');
								}
							);
						});
				}
			});
	});

	$('.flexi-content.table-block .dropdown-title').on('click', function (event) {
		let dropdownList = $(this).next('.dropdown-content');

		$(dropdownList).slideToggle();
		$(this).toggleClass('open');
	});
}
